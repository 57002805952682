import {createApp} from 'vue';

const app = createApp({
    methods: {
        ctaClick: function() {
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
    },
});

app.mount('#home-content');
